
import {computed, defineComponent, onRenderTracked, onUpdated, reactive, ref} from "vue";
import {ACTIVITY_STATUS, ACTIVITY_TYPE} from "@/core/config/Constant";
import {ActivityDetail, LoadPanel} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import type {IActivity} from "@/core/entity/IActivity";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormActivity from "@/views/activity/FormActivity.vue";
import type {INote} from "@/core/entity/INote";
import FormNote from "@/views/activity/FormNote.vue";
import ActivityService from "@/core/services/ActivityService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import UploadForm from "@/views/document/UploadForm.vue";
import ActivityDocument from "@/views/activity/ActivityDocument.vue";
import PendingActivities from "@/views/activity/PendingActivities.vue";
import Audits from "@/views/audit/Audits.vue";
import Notes from "@/views/note/Notes.vue";
import CompletedActivities from "@/views/activity/CompletedActivities.vue";
import AllActivities from "@/views/activity/AllActivities.vue";
import ActivitySummary from "@/views/activity/ActivitySummary.vue";
import MoveActivity from "@/views/activity/MoveActivity.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import MenuLink from "@/components/base/action/MenuLink.vue";
import VisualEmbed from "@/views/quicksight/VisualEmbed.vue";
import Row from "@/components/base/common/Row.vue";
import CallCategory from "@/views/activity/CallCategory.vue";
import CallLength from "@/views/activity/CallLength.vue";

export default defineComponent({
  name: "Activities",
  components: {
    CallLength,
    CallCategory,
    Row,
    VisualEmbed,
    MenuLink,
    DateTimeFormat,
    MoveActivity,
    ActivitySummary,
    AllActivities,
    CompletedActivities,
    Notes,
    Audits,
    PendingActivities,
    ActivityDocument,
    UploadForm,
    FormNote,
    FormActivity, BaseModal, QuickAction
  },
  props: {
    type: {required: true, type: String},
    id: {required: true, type: String},
    area: {type: String, default: () => 'GENERIC'},
  },
  setup(props) {
    const refId = ref<string>(props.id);
    const refType = ref<string>(props.type);
    const state = reactive({
      formTitle: 'Add Activity',
      activity: {
        category: null,
        contactName: '',
        phone: '',
        type: 'CALL_IN',
        occurDt: new Date().getTime(),
        dueDt: '',
        duration: 0,
        note: '',
        title: '',
        status: 'DONE',
        area: 'GENERIC',
      } as any,
      note: {
        activityId: '',
        text: '',
      } as INote,
      activityId: ''
    })
    switch (refType.value) {
      case 'CASE':
        state.activity.cazeId = refId.value
        break;
      case 'CLAIM':
        state.activity.claimId = refId.value
        break
      case 'INVOICE':
        state.activity.invoiceId = refId.value
        break
      case 'POLICY':
        state.activity.policyId = refId.value
        break
      case 'PROCESS':
        state.activity.processId = refId.value
        break
      case 'LOG_REPORT':
        state.activity.logReportId = refId.value
        break
    }
    const store = useStore();
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })


    const addNote = (activityId: string) => {
      state.note.activityId = activityId;
      state.note.text = ''
    }


    const completePage = computed<IActivity[]>(() => store.state.ActivityModule.completePage);
    const allPage = computed<IActivity[]>(() => store.state.ActivityModule.allPage);
    const pendingPage = computed<IActivity[]>(() => store.state.ActivityModule.pendingPage);
    const activity = computed<IActivity>(() => store.state.ActivityModule.activity)
    const viewAccess = computed(() => store.state.ActivityModule.viewAccess)
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
      viewAccess,
      store,
      activity,
      ACTIVITY_TYPE,
      ACTIVITY_STATUS,
      state,
      completePage,
      addNote,
      allPage,
      pendingPage,
      ...LoadPanel(),
      ...ActivityDetail(),
    }
  },
  methods: {
    onEditActivity(activity: any) {
      this.state.formTitle = 'Update Activity';
      this.activity.type = activity?.type?.code
      this.state.activity.id = activity?.id
      this.state.activity.category = activity.category
      this.state.activity.phone = activity.phone
      this.state.activity.contactName = activity.contactName
      this.state.activity.cazeId = activity?.caze?.id
      this.state.activity.claimId = activity?.claim?.id
      this.state.activity.invoiceId = activity?.invoice?.id
      this.state.activity.processId = activity?.process?.id
      this.state.activity.logReportId = activity?.logReport?.id
      this.state.activity.occurDt = activity?.occurDt
      this.state.activity.dueDt = activity?.dueDt
      this.state.activity.duration = activity?.duration
      this.state.activity.duration = activity?.duration
      this.state.activity.note = activity?.note
      this.state.activity.currency = activity?.currency
      this.state.activity.amount = activity?.amount
      this.state.activity.title = activity.title
      this.state.activity.status = activity.status.code
      this.state.activity.area = activity.area
      if (activity?.type?.code == 'CALL_OUT' || activity?.type?.code == 'CALL_IN') {
        const activityForm = this.$refs.baseFormActivityRef as typeof FormActivity
        if (this.type == 'CLAIM') {
          activityForm.loadContacts({claimId: this.id})
        }
        if (this.type === 'CASE') {
          activityForm.loadContacts({cazeId: this.id})
        }
        if (this.type === 'POLICY') {
          activityForm.loadContacts({policyId: this.id})
        }
      }
    },
    addActivity(type: string, title: string) {
      this.state.formTitle = title;
      this.state.activity.type = type;
      this.state.activity.phone = '';
      this.state.activity.contactName = '';
      this.state.activity.category = null;
      this.state.activity.note = ''
      this.state.activity.title = ''
      this.state.activity.occurDt = new Date().getTime();
      this.state.activity.dueDt = '';
      this.state.activity.status = 'DONE'
      this.state.activity.area = this.area
      this.state.activity.id = ''
      if (type == 'CALL_OUT' || type == 'CALL_IN') {
        const activityForm = this.$refs.baseFormActivityRef as typeof FormActivity
        if (this.type == 'CLAIM') {
          activityForm.loadContacts({claimId: this.id})
        }
        if (this.type === 'CASE') {
          activityForm.loadContacts({cazeId: this.id})
        }
        if (this.type === 'POLICY') {
          activityForm.loadContacts({policyId: this.id})
        }
      }
    },
    onSaveActivity() {
      const baseModal = this.$refs.activityForm as typeof BaseModal
      baseModal.hideBaseModal()
      if (this.type === 'CASE') {
        this.store.dispatch(Actions.LOAD_CASE, this.id)
      }
      if (this.type === 'CLAIM') {
        this.store.dispatch(Actions.LOAD_CLAIM, this.id)
      }
      const pendingRef = this.$refs.refPending as typeof PendingActivities;
      pendingRef.paginationLoad();
      const refCompleted = this.$refs.refCompleted as typeof CompletedActivities;
      refCompleted.paginationLoad();
      const allActivities = this.$refs.allActivities as typeof AllActivities;
      allActivities.paginationLoad();
      const activitySummary = this.$refs.activitySummary as typeof ActivitySummary;
      activitySummary.loadData();
    },

    onSaveNote(activityId: string) {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.refNotes as typeof Notes
      notes.paginationLoad();
      this.loadDetail(activityId);
    },

    onUpdateActivity(activity) {
      const baseModal = this.$refs.activityForm as typeof BaseModal
      baseModal.hideBaseModal()
      if (this.type === 'CASE') {
        this.store.dispatch(Actions.LOAD_CASE, this.id)
      }
      if (this.type === 'CLAIM') {
        this.store.dispatch(Actions.LOAD_CLAIM, this.id)
      }
      const auditRef = this.$refs.activityAuditRef as typeof Audits
      auditRef.paginationLoad();
      this.loadDetail(activity.id)
      const pendingRef = this.$refs.refPending as typeof PendingActivities;
      pendingRef.paginationLoad();
      const refCompleted = this.$refs.refCompleted as typeof CompletedActivities;
      refCompleted.paginationLoad();
      const allActivities = this.$refs.allActivities as typeof AllActivities;
      allActivities.paginationLoad();
      const activitySummary = this.$refs.activitySummary as typeof ActivitySummary;
      activitySummary.loadData();
    },

    onDeleteActivity(activity) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ActivityService.delete(activity.id).then(() => {
            const pendingRef = this.$refs.refPending as typeof PendingActivities;
            pendingRef.paginationLoad();
            const refCompleted = this.$refs.refCompleted as typeof CompletedActivities;
            refCompleted.paginationLoad();
            const allActivities = this.$refs.allActivities as typeof AllActivities;
            allActivities.paginationLoad();
            const activitySummary = this.$refs.activitySummary as typeof ActivitySummary;
            activitySummary.loadData();
            this.clearActivityDetail();
            if (this.type === 'CASE') {
              this.store.dispatch(Actions.LOAD_CASE, this.id)
            }
            if (this.type === 'CLAIM') {
              this.store.dispatch(Actions.LOAD_CLAIM, this.id)
            }
          })
        }
      })
    },
    onUpload(activityId) {
      this.state.activityId = activityId;
    },
    onUploadedAudioFile() {
      const baseModalRef = this.$refs.phoneUploadFormRef as typeof BaseModal
      baseModalRef.hideBaseModal()
      const activityDocumentRef = this.$refs.activityDocumentRef as typeof ActivityDocument
      activityDocumentRef.reload()
      const pendingRef = this.$refs.refPending as typeof PendingActivities;
      pendingRef.paginationLoad();
      const refCompleted = this.$refs.refCompleted as typeof CompletedActivities;
      refCompleted.paginationLoad();
      const allActivities = this.$refs.allActivities as typeof AllActivities;
      allActivities.paginationLoad();
      const activitySummary = this.$refs.activitySummary as typeof ActivitySummary;
      activitySummary.loadData();
    },

    onUploadedFile() {
      const baseModalRef = this.$refs.uploadFileFormRef as typeof BaseModal
      baseModalRef.hideBaseModal()
      const activityDocumentRef = this.$refs.activityDocumentRef as typeof ActivityDocument
      activityDocumentRef.reload()
    },
    onMoveActivity() {
      const baseModalRef = this.$refs.moveActivityRef as typeof BaseModal
      baseModalRef.showBaseModal()
    },
    onMovedActivity() {
      const pendingRef = this.$refs.refPending as typeof PendingActivities;
      pendingRef.paginationLoad();
      const refCompleted = this.$refs.refCompleted as typeof CompletedActivities;
      refCompleted.paginationLoad();
      const allActivities = this.$refs.allActivities as typeof AllActivities;
      allActivities.paginationLoad();
      const activitySummary = this.$refs.activitySummary as typeof ActivitySummary;
      activitySummary.loadData();
      this.clearActivityDetail();
      const baseModalRef = this.$refs.moveActivityRef as typeof BaseModal
      baseModalRef.hideBaseModal();
    }
  }
})
